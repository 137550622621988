.hero-sm {
  background: url(../../images/header-sm.jpg) no-repeat;
  background-size: cover;
  height: 200px;
  width: 100%;
}

@media(min-width: 360px) {
  .hero-sm {
    height: 260px;
  }
}

@media(min-width: 480px) {
  .hero-sm {
    height: 310px;
  }
}

@media(min-width: 550px) {
  .hero-sm {
    height: 340px;
  }
}

@media(min-width: 680px) {
  .hero-sm {
    height: 436px;
  }
}

@media(min-width: 960px) {
  .hero-sm {
    display: none;
  }
}

@media(min-width: 960px) {
  .hero-lg {
    background: url(../../images/header-sm.jpg) no-repeat #60cff2;
    background-size: contain;
    background-position: -200px 0;
    height: 573px;
    width: 100%;
  }
}

@media(min-width: 1250px) {
  .hero-lg {
    background: url(../../images/header-sm.jpg) no-repeat #60cff2;
    background-size: contain;
    background-position: -125px 0;
    height: 573px;
    width: 100%;
  }
}

@media(min-width: 1300px) {
  .hero-lg {
    background: url(../../images/header-sm.jpg) no-repeat #60cff2;
    background-size: contain;
    background-position: 0 0;
    height: 573px;
    width: 100%;
  }
}

.hero-lg p,
.hero-lg h1 {
  color: black;
}
