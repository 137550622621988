.wrapper {
    margin-left: auto;
    margin-right: auto;
    max-width: 1300px;
    width: 92%;
}

@media(min-width: 960px) {
  .wrapper {
      align-items: center;
      display: grid;
      grid-template-columns: 60% 40%;
      grid-template-areas: "....... content";
      grid-gap: 0;
      height: 100%;
      margin-left: auto;
      margin-right: auto;
      max-width: 1300px;
      width: 92%;
  }
}

.content {
    grid-area: content;
}
