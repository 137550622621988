footer {
    background-color: $darkBlue;
    border-top: 7px solid $brightBlue;
    color: white;
    margin-top: 2rem;
    text-align: center;
}

@media(min-width: 960px) {
  footer {
    margin-top: 0;
  }
}

small {
    display: block;
}

small p {
    font-size: 0.85rem;
}

.social-icons {
    background-color: $mediumBlue;
}
